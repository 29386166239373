import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import admCartao from "@/components/administratorCard/ListCard.vue";

export default {
  components: {
    HeaderRegister,
    admCartao,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};
